import React from 'react'
import Card from './Card'

const Grid = ({ vehicles }) => {

    return (
        <section className="vehicle--grid">
            {vehicles.map((vehicle, index) => 
                <Card key={`card-${index}`} vehicle={vehicle} />
            )}
        </section>
    )
  }
  
  export default Grid