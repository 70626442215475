import React from 'react';

const Button = ({ className, label, urlTarget, CarModelID, CarModelYear, CarModelName, CTAHandle }) => {
  const slugify = (str) => {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
  };

  if (label === "Prueba de manejo") {
    return (
      <a href="javascript:void(0);" className={`cta-button-offer btn group ${className}`} onClick={CTAHandle}>
        Ver oferta
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="m9 18 6-6-6-6" />
        </svg>
      </a>
    );
  } else {
    return (
      <a href={urlTarget} target="_parent" className={`cta-button-offer btn group ${className}`} data-car-model-id={CarModelID} data-car-model-year={CarModelYear} data-car-model-name={CarModelName} onClick={() => { window.dataLayer.push({ 'event': 'offer_cta_' + slugify(label), 'model_name': CarModelName, 'model_year': CarModelYear }) }}>
        {label}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="m9 18 6-6-6-6" />
        </svg>
      </a>
    );
  }
};

export default Button;
