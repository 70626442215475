import './App.css';
import axios from "axios";
import Grid from './components/Grid';
import Modal from './components/Modal';
import Sidebar from './components/Sidebar';
import Loading from './components/Loading';
import { useEffect, useState } from 'react';
import Slider from './components/Slider';


function App() {

  const [categories, setCategories] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setLoading] = useState(true);

  async function fetchData() {

    const response = await axios.get("https://form.nissan.pr/api/2.0.0/offers/get_all");
    const vehiclesToAppend = response.data.map(vehicle => { return vehicle });
    const categoriesToAppend = response.data.map(item => {
      return item.category;
    });

    setCategories(prevState => [...prevState, ...categoriesToAppend]);
    setVehicles(prevState => [...prevState, ...vehiclesToAppend]);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  if (isLoading) {
    return <Loading />
  } else {
    return (
      <main>
        <div className="container relative ">
          <div className="container mx-auto">
            <Slider />
          </div>

          <div className='lg:px-[65px]'>
            <div className="main-heading">
              <h2>Aprovecha estas ofertas</h2>
              <p>Visita tu consesionario más cercano hoy.</p>
            </div>

            <div className="main-content">
              <Sidebar categories={Array.from(new Set(categories))} vehicles={vehicles} />
              <Grid vehicles={vehicles} />
            </div>

          </div>
        </div>
        <Modal />

      </main>
    );
  }

}

export default App;