import React, { useEffect, useRef } from 'react'
import getCategory from '../utils/getCategory';


const Sidebar = ({ categories, vehicles }) => {
    const closeMenuRef = useRef();
    const categoryMenuRef = useRef();
    const vehiclesMenuRef = useRef();

    const handleWindowResize = () => {
        const isMobile = window.innerWidth < 1025;
        const menus = [categoryMenuRef.current, vehiclesMenuRef.current];
        const lastActive = document.querySelector(".active-widget");

        if (lastActive) lastActive.classList.remove("active-widget");
        
        menus.forEach(menu => isMobile ? menu.classList.add("mobile") : menu.classList.remove("mobile"));
    };
    
    const handleActiveMenu = menu => {
        const lastActive = document.querySelector(".active-widget");

        if (lastActive) lastActive.classList.remove("active-widget");
        menu.classList.add("active-widget");
    };

    const handleCloseMenu = () => {
        const lastActive = document.querySelector(".active-widget");
        lastActive.classList.remove("active-widget");
    };

    const handleSelection = (current, type, group, selection = null) => {
        const vehicleGrid = document.querySelector(".vehicle--grid");
        const cards = vehicleGrid.querySelectorAll('.card');
        const lastActive = document.querySelector(".active-widget");
    
        if (lastActive) lastActive.classList.remove("active-widget");
    
        document.querySelectorAll('input').forEach(item => item.checked = (item === current.parentNode.querySelector('input')));
        cards.forEach(item => item.classList.remove("active-card"));
    
        if (type === 'all') {
            cards.forEach(item => item.classList.add("active-card"));
        } else {
            const targets = document.querySelectorAll(`[data-${group}-id="${selection}"]`);
            targets.forEach(item => item.classList.add("active-card"));              
        }

    };

    useEffect(() => {
        handleWindowResize()
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);


    return (
        <aside className="sidebar">

            <div className="mobile-menu">
                <button onClick={() => handleActiveMenu(categoryMenuRef.current)} className="trigger categories">Categorías</button>
                <button onClick={() => handleActiveMenu(vehiclesMenuRef.current)} className="trigger models">Modelos</button>
            </div>

            <div ref={categoryMenuRef} className="widget categories">
                <div className="widget--mobile-header">
                    <h4>Filtrar por:</h4>
                    <button className="widget--close-btn" ref={closeMenuRef} onClick={() => handleCloseMenu(vehiclesMenuRef)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x">
                            <path d="M18 6 6 18"/><path d="m6 6 12 12"/>
                        </svg>
                    </button>
                </div>
                
                <h3>Categorías</h3>
                <div className="widget--list">
                    <label
                        htmlFor="todos-categoria"
                        onClick={ e => handleSelection(e.target, 'all')}
                        className="widget--list-item">
                        <input id="todos-categoria" type="radio" value="all" className="peer" defaultChecked={true} />
                        <span className="peer-checked:text-black peer-checked:translate-x-2">Todos</span>
                    </label>
                    {   categories.map((category, key) =>

                            <label
                                key={key} 
                                htmlFor={getCategory(category)}
                                id={`l-${category}`}
                                onClick={ e => handleSelection( e.target, "list", "category", category)}
                                className="widget--list-item">
                                    <input id={`v-${getCategory(category)}`} type="radio" value={category} className="peer" />
                                    <span className="peer-checked:text-black peer-checked:translate-x-2">{getCategory(category)}</span>
                            </label>
                        ) 
                    }
                </div>
            </div>

            <div ref={vehiclesMenuRef} className="widget vehicles">
                <div className="widget--mobile-header">
                    <h4>Filtrar por:</h4>
                    <button className="widget--close-btn" ref={closeMenuRef} onClick={() => handleCloseMenu(vehiclesMenuRef)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x">
                            <path d="M18 6 6 18"/><path d="m6 6 12 12"/>
                        </svg>
                    </button>
                </div>

                <h3>Modelos</h3>
                <div id="models" className="widget--list">
                    <label
                        htmlFor="todos-vehiculos"
                        onClick={ e => handleSelection(e.target, 'all')}
                        className="widget--list-item">
                        <input id="todos-vehiculos" type="radio" value="all" className="peer" defaultChecked={true} />
                        <span className="peer-checked:text-black peer-checked:translate-x-2">Todos</span>
                    </label>
                    {   vehicles.map((vehicle, key) =>
                            <label
                                key={key} 
                                htmlFor={(vehicle.car_model.id)}
                                id={`l${vehicle.car_model.id}`}
                                onClick={ e => handleSelection( e.target, "list", "vehicle", vehicle.car_model.id)}
                                className="widget--list-item">
                                <input id={`v-${vehicle.car_model.id}`} type="radio" value={vehicle.car_model.id} className="peer" />
                                <span className="peer-checked:text-black peer-checked:translate-x-2">{vehicle.car_model.name}</span>
                            </label>
                        ) 
                    }
                </div>
            </div>

        </aside>
    )
}

export default Sidebar