const formatCurrency = (amount) => {
    if (typeof amount !== 'number') return 'Invalid input';
    
    return amount.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  };

export default formatCurrency