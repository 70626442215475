import React from 'react';
import { getDomain, scrollTopIframe } from '../utils/domainUtility';
import { gHeight, isMobile } from '../utils/getBodySizes';

const SlideCard = ({
    carname,
    caryear,
    legal,
    buttontext,
    ctaurl,
    ctalabel,
    image_desk,
    image_mob,
}) => {
    let domain = 'http://localhost';

    if (window.location !== window.parent.location) {
        domain = getDomain(document.referrer);
    }

    const handleModal = (event, name, legal) => {
        event.preventDefault();

        const modal = document.querySelector('.modal');
        const modalBody = modal.querySelector('.modal--content-body');
        const modalWrapper = modal.querySelector('.modal--wrapper');
        const modalTitle = modalBody.querySelector('h5');
        const modalContent = modalBody.querySelector('p');

        modalTitle.innerText = `${name}®`;
        modalContent.innerText = legal;

        modal.classList.add('active');

        if (!isMobile) {
            modalWrapper.style.height = gHeight() + 'px';
        } else {
            modalWrapper.classList.add('mobile');
        }

        scrollTopIframe(domain);
    };

    return (
        <>
            <div className="slider--content-wrapper">
                <div className="slider--content">
                    <div className="slider--content-info">
                        <h2 className="uppercase">
                            {carname}® <span>{caryear}</span>
                        </h2>
                        {/* desktop */}
                        <div class="hidden md:block">
                            <p className="price">
                                <button
                                    onClick={
                                        (e) => handleModal(e, carname, legal)
                                    }
                                    dangerouslySetInnerHTML={{ __html: buttontext }}
                                ></button>
                            </p>
                        </div>
                        {/* Mobile */}
                        <div class="w-full block md:hidden text-left">
                            <p dangerouslySetInnerHTML={{ __html: buttontext }}></p>
                        </div>


                    </div>
                    <div className="slider--content-action">
                        <button
                            className="cta-button-offer btn red group"
                            onClick={(e) => handleModal(e, carname, legal)}
                        >
                            Ver oferta
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="m9 18 6-6-6-6"></path>
                            </svg>
                        </button>
                        <a
                            href={ctaurl}
                            target="_parent"
                            className="cta-button-offer btn group"
                        >
                            {ctalabel}
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="m9 18 6-6-6-6"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                <img
                    className="opacity-0 md:opacity-100"
                    src={image_desk}
                    alt={carname}
                />
                <img
                    className="opacity-100 md:opacity-0"
                    src={image_mob}
                    alt={carname}
                />
            </div>
        </>
    );
};

export default SlideCard;
