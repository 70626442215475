import React from 'react'


const handleModal = () => {
  const modal = document.querySelector('.modal');
  modal.classList.remove("active")
}

const Modal = () => {
  return (
    <div className="modal">
      <div className='modal--wrapper'>
        <div id="modal--content" className="modal--content">
          <div className="modal--content-tile">
            <h2>Aprovecha esta oferta</h2>
            <button className="widget--close-btn" onClick={handleModal}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M18 6 6 18" /><path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
          <div className="modal--content-body">
            <h5>...</h5>
            <p>...</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal