
export const gWidth = () => {
    return document.body.clientWidth;
}

export const gHeight = () => {
    return (document.body.clientHeight / 2);
}

export const gHeightOrg = () => {
    return document.body.clientHeight;
}

export const isMobile = gWidth() <= 768;

