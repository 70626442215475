export const getDomain = (link) => {
    var iframeDomain, parser = document.createElement('a');

    parser.href = link;
    iframeDomain = parser.protocol + '//' + parser.hostname;

    return iframeDomain;
}


export const scrollTopIframe = (domain) => {
    let message = {
        type: 'scrolltopiframe'
    };
    window.parent.postMessage(JSON.stringify(message), domain);
    window.parent.postMessage(JSON.stringify(message), 'https://nissan.pr/');
    window.parent.postMessage(JSON.stringify(message), 'https://es-pr.dark.prod.heliosnissan.net/');
    window.parent.postMessage(JSON.stringify(message), 'https://offers.nissan.pr/');
    window.parent.postMessage(JSON.stringify(message), 'https://sta-offers-nissan.lihdigital.com/');

}


