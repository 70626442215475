import React from "react"

const Loading = () => {
    return (
        <main className="loading">
            <div className="container">
                <div className="flex flex-col items-center justify-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-24 h-24 animate-spin stroke-brand-red"><path d="M21 12a9 9 0 1 1-6.219-8.56" /></svg>
                    <h2>Cargando</h2>
                </div>
            </div>
        </main>
    )
}

export default Loading